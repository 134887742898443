<template>
  <div class="bg flexCenter">
    <div class="login_page flexCenter">

      <div class="box_left flexCenter">
        <img src="../../assets/images/log_pic.png"
             class="login_bg">
      </div>
      <div class="box_right flexCenter"
           v-loading='login_running'>
        <div class="login_box">
          <h2 class="title">
            {{ $t('message.login') }}
            <!-- 登录 -->
          </h2>
          <!-- 表单 -->
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   class="demo-ruleForm">
            <!-- 登陆账号 -->
            <div class="input_box">
              <span class="lab">
                <!-- 登录账号 -->
                {{ $t('message.uiserName') }}
              </span>
              <el-form-item prop="login_name">

                <el-input v-model="ruleForm.login_name"
                          placeholder=""
                          select-when-unmatched="true"
                          @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item>
            </div>
            <!-- 登陆密码 -->
            <div class="input_box">
              <span class="lab">{{ $t('message.password') }}</span>
              <el-form-item prop="login_password"
                            style="margin-bottom: 8px;">
                <el-input placeholder=""
                          v-model="ruleForm.login_password"
                          show-password
                          @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item>
            </div>
            <div class="text">
              <span @click="forgotPassword = true">{{ $t('message.forgotPassword') }}</span>
            </div>
            <!-- 登陆按钮 -->
            <el-form-item>
              <el-button type="primary"
                         style="width: 100%;font-size: 15px;"
                         @click="submitForm('ruleForm')">
                {{$t('message.login')}}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <warning :type="isWarn"
              :errorText="errorText"
              @setType="closewarn">
    </warning>
    <el-dialog
      title=""
      :visible.sync="forgotPassword"
      width="450px">
      <div class="title">{{$t('message.contact')}}</div>
      <div class="content_item" style="margin-top: 40px;">
          <span>{{$t('message.name')}}：</span>
        <span>{{$t('message.MrRuan')}}</span>
      </div>
      <div class="content_item">
        <span>{{$t('message.qq')}}：</span>
        <span>47599126</span>
      </div>
      <div class="content_item">
        <span>{{$t('message.E-mail')}}：</span>
        <span>writers@Hinovel.com</span>
      </div>
      <div class="btn button-me" @click="forgotPassword = false">{{$t('message.ok')}}</div>
    </el-dialog>

  </div>
</template>

<script>
import { login, outLogin } from '@/apis/api'
export default {
  name: 'Login',
  data () {
    return {
      //表单数据
      ruleForm: {
        //登录账号
        login_name: '',
        //登录密码
        login_password: ''
      },
      //表单验证
      rules: {
        login_name: [
          { required: true, message: this.$t('message.pleaseEnterUsername'), trigger: 'change' }
        ],
        login_password: [
          //请输入登录密码
          { required: true, message: this.$t('message.pleaseEnterPassword'), trigger: 'change' },
          // 长度在 6 到 16 个字符
          { min: 8, max: 50, message: this.$t('message.APasswordShouldBeAtLeast8Characters'), rigger: 'change' }
        ]
      },
      //是否执行钟
      login_running: false,
      forgotPassword: false,
      isWarn: '',
      errorText: ''
    }
  },
  mounted () {
    //清除之前用户信息
    this.outLogin()
  },
  methods: {
    //验证表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.Click_login()
        }
      })
    },
    //登录
    async Click_login () {
      this.login_running = true
      let res = await login(this.ruleForm)
      this.login_running = false
      if (res.code === 1) {
        console.log(res)
        let user_data = JSON.stringify(res.data)
        localStorage.setItem('user_data', user_data)
        this.$store.commit('setUserInfo', res.data)
        this.$router.push({
          path: res.data.sign_status === 0 ? '/' : '/account'
        })
        return
      } else if (res.code === 1001 ) {
        this.errorText = this.$t('message.userNotFound')
      } else if (res.code === 1002 ) {
        this.errorText = this.$t('message.passwordIncorrect')
      } else if (res.code === 1006 || res.code === 1008) {
        this.errorText = this.$t('message.loginFailed')
      }
      this.isWarn = 'warn'
    },
    // 清除登录状态
    async outLogin() {
      // await outLogin()
      this.$store.commit('setUserInfo', '')
      localStorage.setItem('user_data', '')
    },
    // 登录错误提示
    closewarn (val) {
      this.isWarn = val
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 30px;
}
.bg {
  width: 100%;
  height: 100%;
  background: #1a77ff;
  padding: 20px;
}
.login_page {
  width: 1200px;
  height: 678px;
  background: #ffffff;
  border-radius: 20px;
}
.box_left {
  width: 720px;
  height: 100%;
  background: #f6f9ff;
  border-radius: 20px;
  border-radius: 20px;
  .login_bg {
    width: 644px;
    height: 454px;
  }
}
.box_right {
  width: 480px;
  height: 100%;
  .login_box {
    width: 280px;
    .title {
      font-family: PingFang-SC-Heavy;
      font-size: 36px;
      color: #3d434d;
      margin-bottom: 47px;
    }
  }
  .text {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: right;
    color: #999999;
    font-size: 14px;
    span {
      cursor: pointer;
      padding: 0 5px;
    }
  }
}

.input_box {
  position: relative;
  .lab {
    background: #fff;
    position: absolute;
    top: -8px;
    left: 20px;
    z-index: 1;
    font-family: PingFang-SC-Regular;
    font-size: 14px;
    color: #3d434d;
    line-height: 14px;
  }
}

.el-dialog__body {
  .title {
    font-size: 24px;
    margin-top: 2px;
    font-weight: 500;
    color: #15161A;
    text-align: center;
  }
  .content_item {
    width: 264px;
    margin: 0 auto;
    margin-top: 18px;
    span:nth-child(1) {
      font-size: 16px;
      font-weight: 400;
      color: #656565;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      margin-left: 8px;
    }
  }
  .btn {
    cursor: pointer;
    width: 200px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    margin-top: 46px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #3D76FF;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
.flexCenter {
  .el-dialog {
    border-radius: 16px;
  }
  .el-input__inner{
    color: #32343A;
  }
}
.bg {
  .el-dialog {
    border-radius: 10px !important;
    .el-dialog__header {
      padding: 10px 10px 10px;
      .el-icon {
        font-size: 24px;
      }
    }
    .el-dialog__headerbtn {
      display: none;
    }
  }
}
</style>
